<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="选择时间">
          <el-date-picker
            v-model="formInline.time"
            clearable
            type="daterange"
            :picker-options="pickerOptions"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch" icon="el-icon-search">查询</el-button>
          <el-button type="success" v-if="tableData.length>0" @click="exportXcle" icon="el-icon-download">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      id="exportTable"
      show-summary>
      <el-table-column prop="pointday" label="日期" align="center" ></el-table-column>
      <el-table-column prop="shouru" label="积分收入（积分）" align="center"></el-table-column>
      <el-table-column prop="fafang" label="积分支出（积分）" align="center"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import { systemReportList } from '@/api/reportStatistics'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
export default {
  data() {
    return {
      choiceDate0: '',
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate0 = minDate.getTime();
          if (maxDate) {
            this.choiceDate0 = '';
          }
        },
        disabledDate:
        (time) => {
          let choiceDateTime = new Date(this.choiceDate0).getTime();
          const minTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() - 1);
          const maxTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() + 1);
          const min = minTime;
          const newDate = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1;
          const max = newDate < maxTime ? newDate : maxTime;
          //如果已经选中一个日期 则 返回 该日期前后一个月时间可选
          if (this.choiceDate0) {
            return time.getTime() < min || time.getTime() > max;
          }
          //若一个日期也没选中 则 返回 当前日期以前日期可选
          return time.getTime() > newDate;
        }
      },
      formInline: {
        time: '',
      },
      tableData: [],
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      systemReportList({
        startDate: this.formInline.time[0] || '',
        endDate: this.formInline.time[1] || ''
      }).then( res => {
        if(res.result == 200) {
          let detail = res.detail;
          this.tableData = detail;
        }
      })
    },
    onSearch() {
      this.getList();
    },
    //导出表格
    exportXcle() {
      let gatherData = { raw: true };
      let grid = XLSX.utils.table_to_book(
        document.querySelector("#exportTable"),
        gatherData
      );
      let workbook = XLSX.write(grid, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        FileSaver.saveAs(
          new Blob([workbook], {
            type: "application/octet-stream",
          }),
          "系统积分统计.xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, workbook);
      }
      return workbook;
    },
  },
};
</script>
<style lang="scss" scoped></style>
